import store from 'store';

import * as uiActions from 'store/slices/ui';

const dispatch = store.dispatch;

export interface ToastState {
    duration?: null | number;
    visible: boolean;
    status: 'success' | 'danger' | 'neutral' | 'warning';
    message: string;
}

interface ToastService {
    SUCCESS: 'success';
    DANGER: 'danger';
    NEUTRAL: 'neutral';
    WARNING: 'warning';
    show: (
        message: ToastState['message'],
        status?: ToastState['status'],
        duration?: ToastState['duration']
    ) => void;
    hide: () => void;
}

const toastService: ToastService = {
    SUCCESS: 'success',
    DANGER: 'danger',
    NEUTRAL: 'neutral',
    WARNING: 'warning',
    show: (message = '', status = toastService.NEUTRAL, duration = 5) => {
        dispatch(uiActions.showToast({ message, status, duration }));
    },
    hide: () => {
        dispatch(uiActions.hideToast());
    }
};

export default toastService;
