import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ToastState } from 'services/toast';

interface UIReducerState {
    toast: ToastState;
}

const initialState: UIReducerState = {
    toast: {
        duration: null,
        visible: false,
        status: 'neutral',
        message: ''
    }
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showToast: (
            state: UIReducerState,
            { payload }: PayloadAction<Omit<ToastState, 'visible'>>
        ) => {
            state.toast = { ...payload, visible: true };
        },
        hideToast: (state: UIReducerState) => {
            state.toast = initialState.toast;
        }
    }
});

export const { showToast, hideToast } = uiSlice.actions;

export default uiSlice.reducer;
