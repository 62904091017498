import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { PrivateUser } from 'services/types';

export interface AuthReducerState {
    isAuthenticated: boolean;
    user: PrivateUser | Record<string, never>;
    token: null | string;
    refreshToken: null | string;
}

const initialState: AuthReducerState = {
    isAuthenticated: false,
    user: {},
    token: null,
    refreshToken: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state: AuthReducerState) => {
            state.isAuthenticated = true;
        },
        setUser: (state: AuthReducerState, { payload }: PayloadAction<PrivateUser>) => {
            state.user = payload;
        },
        setToken: (state: AuthReducerState, { payload }: PayloadAction<string>) => {
            state.token = payload;
        },
        setRefreshToken: (state: AuthReducerState, { payload }: PayloadAction<string>) => {
            state.refreshToken = payload;
        },
        logout: () => initialState
    }
});

export const { login, setUser, setToken, setRefreshToken, logout } = authSlice.actions;

export default authSlice.reducer;
