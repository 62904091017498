import { configureStore } from '@reduxjs/toolkit';

import { Action } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './slices';

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { logger } = require('redux-logger');

    middlewares.push(logger);
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'locale']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: [...middlewares]
});

const persistor = persistStore(store);

export { persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

type Payload<T> = {
    [P in keyof T]: T[P];
};

export type StoreAction<T> = Action<string> &
    Partial<Payload<T>> & {
        type: string;
    };

export default store;
