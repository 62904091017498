import React from 'react';
import parse from 'html-react-parser';

type HTMLParserProps = {
    content?: string | null;
};

const HTMLParser: React.FC<HTMLParserProps> = ({ content }) => {
    return <>{parse(String(content || ''))}</>;
};

export default HTMLParser;
